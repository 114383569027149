import request from "@/utils/request.js";

/**
 * @description: 修改抄表记录读数或者通知单编号
 * @param {*} query
 */
interface IModified {
  recordId: number,
  dosage?: number,
  noticeSheetId?: string
}
export function modified(params: IModified) {
  return request({
    url: "/api/WaterRecord/Modified",
    method: "post",
    data: params,
  });
}

//添加人工修正数据记录
export function addManualRecord(params: any) {
  return request({
    url: "/api/WaterRecord/AddManualRecord",
    method: "post",
    data: params,
  });
}
//修改人工修正数据记录
export function modifyManualRecord(params: any) {
  return request({
    url: "/api/WaterRecord/ModifyManualRecord",
    method: "post",
    data: params,
  });
}

//修改人工修正数据记录
export function disableManualRecord(params: any) {
  return request({
    url: "/api/WaterRecord/DisableManualRecord",
    method: "post",
    data: params,
  });
}

//获取单个人工抄表记录
export function getOneManualRecord(params: any) {
  return request({
    url: "/api/WaterRecord/GetOneManualRecord",
    method: "get",
    params,
  });
}